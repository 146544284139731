import { SharedChartOptions } from '@ess/shared/utils/models';

import { SHARED_COLORS } from './shared-colors.const';

export const SHARED_CHART_DEFAULT_OPTIONS: SharedChartOptions = {
  tooltip: {
    trigger: 'axis',
    show: true,
    shadowColor: 'transparent',
    borderColor: SHARED_COLORS['neutral-variant']['400'],
    borderRadius: 8,
    padding: 16,
    textStyle: {
      fontSize: 10,
      lineHeight: 12,
    },
    position: (point, params, el, rect, size) => {
      let container: HTMLElement = el as HTMLElement;
      while (
        container &&
        container.tagName !== 'BODY' &&
        !container.classList.contains('ess-shared-chart-container') &&
        container.parentElement
      ) {
        container = container.parentElement;
      }

      let x = point[0];
      let y = point[1];

      const margin = {
        left: 240,
        right: 64,
        top: 120,
        bottom: 64,
      };

      const offset = 12;

      const tooltip: DOMRect | null = (el as HTMLDivElement).getBoundingClientRect() ?? null;
      const chart: DOMRect | null = (el as HTMLDivElement).parentElement?.getBoundingClientRect() ?? null;

      if (tooltip && chart) {
        //   HORIZONTAL CHECK - define x
        if (chart.x + x + offset + tooltip.width > container.clientWidth - margin.right) {
          // show tooltip to the left
          x = x - tooltip.width - offset;
        } else {
          // show tooltip to the right
          x = x + offset;
        }

        //   VERTICAL CHECK - define y
        if (chart.y + y - offset - tooltip.height < margin.top) {
          // show tooltip below
          y = y + offset;
        } else {
          // show tooltip above
          y = y - tooltip.height - offset;
        }
      }

      return [x, y];
    },
  },

  title: {
    left: 'left',
  },

  grid: {
    left: '20px',
    right: '20px',
    containLabel: true,
    top: '12px',
    bottom: '4px',
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    axisLabel: { color: '#B0B2B3', margin: 12 },
  },

  yAxis: {
    type: 'value',
    axisLabel: { color: '#B0B2B3' },
  },

  legend: {
    show: false,
  },
};

export const SHARED_CHART_RATIOS: { [key: number]: number } = {
  1: 3 / 20,
  2: 32 / 100,
  3: 1 / 2,
};
export const SHARED_CONFIGURABLE_CHART_RATIOS: { [key: number]: number } = {
  1: 19 / 100,
  2: 2 / 5,
  3: 26 / 50,
};
