export const SHARED_COLORS = {
  inherit: 'inherit',
  current: 'currentColor',
  transparent: 'transparent',
  white: '#fff',
  base: {
    primary: '#202022',
    secondary: '#344061',
    tertiary: '#5F6A89',
    'note-100': '#63729D',
    'note-200': '#435074',
    disabled: '#B0B2B3',
    'disabled-button': '#5D6164',
    complementary: '#9EA8C3',
  },
  buttons: {
    primary: '#3D64E3',
    secondary: '#DCE1FF',
    outline: '#C3C7CC',
    disabled: '#DFE1E6',
  },
  priority: {
    critical: '#B81F1E',
    high: '#DB3A33',
    medium: '#E37500',
    low: '#1665DC',
    none: '#8B98C3',
    paused: '#6D16DC',
    'low-light': '#DEEBFF',
    'none-light': '#E0E1E3',
    'to-risk-light': '#DEE0FF',
  },
  primary: {
    50: '#F1F2FB',
    100: '#E7E9FB',
    200: '#C1C8F5',
    300: '#97A4EE',
    400: '#6880E8',
    DEFAULT: '#3D64E3',
    600: '#0048DC',
    700: '#002ABA',
    800: '#002295',
    900: '#001B77',
  },
  accent: {
    50: '#FCFDFF',
    100: '#F4F7FF',
    200: '#E0EAFF',
    300: '#CCDDFF',
    400: '#B4CEFF',
    DEFAULT: '#9EBCFF',
    600: '#8BACFF',
    700: '#7793FA',
    800: '#6373D7',
    900: '#4F5CAC',
  },
  success: {
    25: '#F1FCF3',
    50: '#E5F7E9',
    100: '#BEEBC7',
    200: '#93DDA2',
    300: '#67CF7D',
    DEFAULT: '#47C561',
    500: '#26BB45',
    600: '#22B53E',
    700: '#1CAC36',
    800: '#17A42E',
    900: '#0D961F',
  },
  warning: {
    50: '#FFF9E9',
    100: '#FFEBBA',
    200: '#FFDE8A',
    300: '#FFD15A',
    DEFAULT: '#FFC32A',
    500: '#DDA618',
    600: '#BB890A',
    700: '#996E00',
    800: '#775600',
    900: '#553D00',
  },
  warn: {
    50: '#FFF8F6',
    100: '#FFEDEA',
    200: '#FFDAD6',
    300: '#FFB4AB',
    400: '#FF897D',
    DEFAULT: '#FF5449',
    600: '#DB3A33',
    700: '#B81F1E',
    800: '#93000A',
    900: '#690005',
  },
  neutral: {
    50: '#FAFAFA',
    100: '#F5F5F5',
    200: '#EEEEEE',
    300: '#E0E0E0',
    400: '#BDBDBD',
    DEFAULT: '#9E9E9E',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
  },
  'neutral-variant': {
    50: '#F9FAFC',
    100: '#F5F7F9',
    200: '#EFF2F4',
    300: '#EAECF0',
    400: '#DFE1E6',
    DEFAULT: '#C3C7CC',
    600: '#A8ACB2',
    700: '#888B91',
    800: '#696B70',
    900: '#494B4F',
  },
  paused: {
    50: '#f0e8fc',
    100: '#e2d0f8',
    200: '#c5a2f1',
    300: '#a773ea',
    400: '#8a45e3',
    DEFAULT: '#6d16dc',
    600: '#5712b0',
    700: '#410d84',
    800: '#2c0958',
    900: '#16042c',
  },
  'mat-background': {
    DEFAULT: '#F5F7F9',
    element: '#F4F7FF',
  },
  'slate-light': {
    8: 'rgba(255,255,255,0.08)',
    12: 'rgba(255,255,255,0.12)',
    16: 'rgba(255,255,255,0.16)',
    64: 'rgba(255,255,255,0.64)',
  },
  'slate-primary': {
    8: 'rgba(61,100,227,0.08)',
    12: 'rgba(61,100,227,0.12)',
    16: 'rgba(61,100,227,0.16)',
  },
  'slate-dark': {
    8: 'rgba(0,0,0,0.08)',
    12: 'rgba(0,0,0,0.12)',
    16: 'rgba(0,0,0,0.16)',
  },
  chart: {
    50: '#5769AA',
    100: '#021C74',
    200: '#22A692',
    300: '#027F6B',
    400: '#009FC4',
    500: '#E954D1',
    600: '#93057C',
    700: '#AF7C5F',
  },
};
