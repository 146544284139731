import { SHARED_COLORS } from './shared-colors.const';
import { SHARED_TYPOGRAPHY } from './shared-typography.const';

export const SHARED_TAILWIND_THEME = {
  screens: {
    sm: '576px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
    xxl: '1440px',
  },
  fontFamily: {
    base: ['Roboto', 'Helvetica Neue', 'sans-serif'],
  },
  fontWeight: {
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
  },
  fontSize: {
    ...SHARED_TYPOGRAPHY,
  },
  colors: {
    ...SHARED_COLORS,
  },
  extend: {
    lineHeight: {
      '2': '0.5rem',
      'super-tight': '0.75',
    },
    opacity: {
      '8': '0.08',
      '12': '0.12',
    },
    boxShadow: {
      menu: '0px 3px 3px 0px #01408F14',
    },
    borderRadius: {
      xs: '1px',
    },
    width: {
      '8.5': '2.125rem',
      '12.5': '3.125rem',
      '70': '17.5rem',
    },
    height: {
      '12.5': '3.125rem',
      '34': '8.5rem',
      '160': '40rem',
    },
    minHeight: {
      '6': '1.5rem',
      '8': '2rem',
      '10': '2.5rem',
      '11': '2.75rem',
      '66': '16.5rem',
      '71': '17.75rem',
      '91': '22.75rem',
      '96': '24rem',
    },
    minWidth: {
      '4': '1rem',
      '15': '3.75rem',
      '38': '9.5rem',
      '72': '18rem',
      '78': '19.5rem',
      '80': '20rem',
    },
    maxHeight: {
      61: '15.25rem',
      '80vh': '80vh',
      'filters-menu': '29rem',
      'grid-wrapper': 'calc(100vh - 14.25rem)',
      inherit: 'inherit',
    },
    maxWidth: {
      '1/2': '50%',
      'filters-form-field': '13.5rem',
    },
    backgroundImage: {
      'google-ads': "url('/assets/logotypes/google-a-d-s.svg')",
      'google-sc': "url('/assets/logotypes/google-search-console.svg')",
    },
    gridAutoColumns: {
      fit: 'minmax(0, max-content)',
    },
  },
};
