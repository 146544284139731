import { SharedChartTheme } from '@ess/shared/utils/models';

export const SHARED_CHART_DEFAULT_THEME: SharedChartTheme = {
  color: ['#001b77'],
  backgroundColor: 'rgba(0,0,0,0)',
  textStyle: {},
  title: {
    textStyle: {
      color: '#008acd',
    },
    subtextStyle: {
      color: '#aaaaaa',
    },
  },
  line: {
    itemStyle: {
      borderWidth: 1,
    },
    lineStyle: {
      width: 2,
    },
    symbolSize: 6,
    symbol: 'circle',
    smooth: false,
  },
  radar: {
    itemStyle: {
      borderWidth: 1,
    },
    lineStyle: {
      width: 2,
    },
    symbolSize: 3,
    symbol: 'emptyCircle',
    smooth: true,
  },
  bar: {
    itemStyle: {
      barBorderWidth: '0',
      barBorderColor: '#b0b2b3',
    },
  },
  pie: {
    itemStyle: {
      borderWidth: '0',
      borderColor: '#b0b2b3',
    },
  },
  scatter: {
    itemStyle: {
      borderWidth: '0',
      borderColor: '#b0b2b3',
    },
  },
  boxplot: {
    itemStyle: {
      borderWidth: '0',
      borderColor: '#b0b2b3',
    },
  },
  parallel: {
    itemStyle: {
      borderWidth: '0',
      borderColor: '#b0b2b3',
    },
  },
  sankey: {
    itemStyle: {
      borderWidth: '0',
      borderColor: '#b0b2b3',
    },
  },
  funnel: {
    itemStyle: {
      borderWidth: '0',
      borderColor: '#b0b2b3',
    },
  },
  gauge: {
    itemStyle: {
      borderWidth: '0',
      borderColor: '#b0b2b3',
    },
  },
  candlestick: {
    itemStyle: {
      color: '#d87a80',
      color0: '#2ec7c9',
      borderColor: '#d87a80',
      borderColor0: '#2ec7c9',
      borderWidth: 1,
    },
  },
  graph: {
    itemStyle: {
      borderWidth: '0',
      borderColor: '#b0b2b3',
    },
    lineStyle: {
      width: 1,
      color: '#aaaaaa',
    },
    symbolSize: 2,
    symbol: 'emptyCircle',
    smooth: false,
    color: ['#001b77'],
    label: {
      color: '#ffffff',
    },
  },
  map: {
    itemStyle: {
      areaColor: '#dddddd',
      borderColor: '#eeeeee',
      borderWidth: 0.5,
    },
    label: {
      color: '#d87a80',
    },
    emphasis: {
      itemStyle: {
        areaColor: 'rgba(254,153,78,1)',
        borderColor: '#444',
        borderWidth: 1,
      },
      label: {
        color: 'rgb(100,0,0)',
      },
    },
  },
  geo: {
    itemStyle: {
      areaColor: '#dddddd',
      borderColor: '#eeeeee',
      borderWidth: 0.5,
    },
    label: {
      color: '#d87a80',
    },
    emphasis: {
      itemStyle: {
        areaColor: 'rgba(254,153,78,1)',
        borderColor: '#444',
        borderWidth: 1,
      },
      label: {
        color: 'rgb(100,0,0)',
      },
    },
  },
  categoryAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: '#e0e0e0',
      },
    },
    axisTick: {
      show: true,
      lineStyle: {
        color: '#e0e0e0',
      },
    },
    axisLabel: {
      show: true,
      color: '#e0e0e0',
    },
    splitLine: {
      show: false,
      lineStyle: {
        color: ['#eee'],
      },
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
      },
    },
  },
  valueAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: '#b0b2b3',
      },
    },
    axisTick: {
      show: true,
      lineStyle: {
        color: '#b0b2b3',
      },
    },
    axisLabel: {
      show: true,
      color: '#b0b2b3',
    },
    splitLine: {
      show: false,
      lineStyle: {
        color: ['#eee'],
      },
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
      },
    },
  },
  logAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: '#b0b2b3',
      },
    },
    axisTick: {
      show: true,
      lineStyle: {
        color: '#e0e0e0',
      },
    },
    axisLabel: {
      show: true,
      color: '#e0e0e0',
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: ['#eee'],
      },
    },
    splitArea: {
      show: true,
      areaStyle: {
        color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
      },
    },
  },
  timeAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: '#008acd',
      },
    },
    axisTick: {
      show: true,
      lineStyle: {
        color: '#e0e0e0',
      },
    },
    axisLabel: {
      show: true,
      color: '#e0e0e0',
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: ['#eee'],
      },
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
      },
    },
  },
  toolbox: {
    iconStyle: {
      borderColor: '#2ec7c9',
    },
    emphasis: {
      iconStyle: {
        borderColor: '#18a4a6',
      },
    },
  },
  legend: {
    textStyle: {
      color: '#e0e0e0',
    },
  },
  tooltip: {
    axisPointer: {
      lineStyle: {
        color: '#b0b2b3',
        width: '1',
      },
      crossStyle: {
        color: '#b0b2b3',
        width: '1',
      },
    },
  },
  timeline: {
    lineStyle: {
      color: '#b0b2b3',
      width: 1,
    },
    itemStyle: {
      color: '#008acd',
      borderWidth: 1,
    },
    controlStyle: {
      color: '#008acd',
      borderColor: '#008acd',
      borderWidth: 0.5,
    },
    checkpointStyle: {
      color: '#2ec7c9',
      borderColor: '#2ec7c9',
    },
    label: {
      color: '#008acd',
    },
    emphasis: {
      itemStyle: {
        color: '#a9334c',
      },
      controlStyle: {
        color: '#008acd',
        borderColor: '#008acd',
        borderWidth: 0.5,
      },
      label: {
        color: '#008acd',
      },
    },
  },
  visualMap: {
    color: ['#5ab1ef', '#e0ffff'],
  },
  dataZoom: {
    backgroundColor: 'rgba(47,69,84,0)',
    dataBackgroundColor: '#efefff',
    fillerColor: 'rgba(182,162,222,0.2)',
    handleColor: '#008acd',
    handleSize: '100%',
    textStyle: {
      color: '#333333',
    },
  },
  markPoint: {
    label: {
      color: '#ffffff',
    },
    emphasis: {
      label: {
        color: '#ffffff',
      },
    },
  },
  recommendationsDialog: {
    offset: {
      x: 0,
      y: 28,
    },
  },
};
