export const SHARED_TYPOGRAPHY: { [key: string]: [string, { lineHeight: string; fontWeight: number }] } = {
  'display-lg': ['3.5625rem', { lineHeight: '4rem', fontWeight: 400 }],
  display: ['2.8125rem', { lineHeight: '3.25rem', fontWeight: 400 }],
  'display-sm': ['2.25rem', { lineHeight: '2.75rem', fontWeight: 400 }],

  'headline-lg': ['2rem', { lineHeight: '2.5rem', fontWeight: 400 }],
  headline: ['1.75rem', { lineHeight: '2.25rem', fontWeight: 400 }],
  'headline-sm': ['1.5rem', { lineHeight: '2rem', fontWeight: 400 }],
  'headline-sm-medium': ['1.5rem', { lineHeight: '1.5rem', fontWeight: 500 }],
  'headline-xs': ['1.25rem', { lineHeight: '1.5rem', fontWeight: 700 }],
  'headline-xs-medium': ['1.25rem', { lineHeight: '1.5rem', fontWeight: 500 }],

  'title-xl': ['2.125rem', { lineHeight: '2.25rem', fontWeight: 700 }],
  'title-lg': ['1.375rem', { lineHeight: '1.75rem', fontWeight: 400 }],
  title: ['1rem', { lineHeight: '1.5rem', fontWeight: 500 }],
  'title-bold': ['1rem', { lineHeight: '1.5rem', fontWeight: 700 }],
  'title-sm': ['0.875rem', { lineHeight: '1.25rem', fontWeight: 500 }],

  'label-lg': ['0.875rem', { lineHeight: '1.25rem', fontWeight: 500 }],
  label: ['0.75rem', { lineHeight: '1rem', fontWeight: 500 }],
  'label-sm': ['0.6875rem', { lineHeight: '1rem', fontWeight: 500 }],
  'label-xs': ['0.625rem', { lineHeight: '0.75rem', fontWeight: 400 }],

  xl: ['1.125rem', { lineHeight: '1.5rem', fontWeight: 400 }],
  lg: ['1rem', { lineHeight: '1.5rem', fontWeight: 400 }],
  base: ['0.875rem', { lineHeight: '1.25rem', fontWeight: 400 }],
  'base-medium': ['0.875rem', { lineHeight: '1.5rem', fontWeight: 500 }],
  'base-bold': ['0.875rem', { lineHeight: '1.25rem', fontWeight: 700 }],
  sm: ['0.75rem', { lineHeight: '1rem', fontWeight: 400 }],
  xs: ['0.625rem', { lineHeight: '1rem', fontWeight: 400 }],
  'xs-medium': ['0.625rem', { lineHeight: '1rem', fontWeight: 500 }],
  'xs-bold': ['0.625rem', { lineHeight: '1rem', fontWeight: 700 }],
};
